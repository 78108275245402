import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from 'react-router-dom';
import { convertToRaw } from 'draft-js';

import _ from "lodash";
import {
  getConfig,
  getCompanyListDetails,
  getUserDetailsFromJwtToken,
} from "../../../../../service/service";

import { getHelperText } from "../../../../../utils/helper";

import BinarywayButton from "../../../../UI-Components/Button/Button";
import BinarywayDropdown from "../../../../UI-Components/Drop-Down/Drop-Down";
import BinarywayInput from "../../../../UI-Components/Input/Input";
import BinarywayTextArea from "../../../../UI-Components/Text-Area/Text-Area";
import BinarywayRichTextEditor from "../../../../UI-Components/Rich-Text-Editor/Rich-Text-Editor";

import { appConstants } from "../../../../../service/constants";
import { AppContext } from "../../../../../service/context";
import { RuleBuilderContext } from "../../../../../service/context";

import "./RulesBasicDetails.module.css";
const styles = {
  control: (base) => ({
    ...base,
    minHeight: 32,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const RulesBasicDetails = (props) => {
  const { appData, setAppData } = useContext(AppContext);
  const { ruleBuilderData, setRuleBuilderData } = useContext(
    RuleBuilderContext
  );
  const [rulesBasicDetailsData, setRulesBasicDetails] = useState({
    configKeys: [],
    companyList: [],
    userDetails: {},
  });
  const [showTooltip, setShowTooltip] = useState(false);
  const [enableNextButton, setEnableNextButton] = useState(false);
  const history = useHistory();
  const originalRemedy = '{"blocks":[{"key":"7po5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}'
  const [remedyEditorState, setRemedyEditorState] = useState('{"blocks":[{"key":"7po5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}');
  const [helperErrors, setHelperErrors] = useState({
      remedy: ""
   })

  useEffect(() => {
    let ruleData = null
    let editRule = false
    if (history && history.location && history.location.state && history.location.state.isEdit) {

      editRule = true;
      ruleData = history.location.state.editData;
      setRuleBuilderData({
        ...ruleBuilderData,
        ruleName: ruleData.NAME,
        companyId: ruleData.COMPANY_ID,
        detail: ruleData.DESCRIPTION,
        remedy: ruleData.REMEDY,
        targetVersion: ruleData.TARGET_VERSION,
        ruleId: ruleData.ID,
        category: ruleData.CATEGORY_ID,
        ruleCriteriaValue: ruleData.CRITERIA,
        componentAtrributeValue: ruleData.ATTRIBUTE_PROPERTY
      });

      ruleBuilderData.ruleName = ruleData.NAME;
    }
    onLoadData(editRule, ruleData);
    setRemedyEditorState(ruleData && ruleData.REMEDY && ruleData.REMEDY !=""? ruleData.REMEDY : remedyEditorState); 
  }, []);

  useEffect(() => {
    validateForm();
  }, [ruleBuilderData]);

  const onLoadData = async (editRule, editRuleData) => {
    const userDetails = getUserDetailsFromJwtToken();
    let companyList = null;
    if (userDetails && userDetails.role === "super admin") {
      companyList = await getCompanyListDetails();
    }
    const configKeys = await getConfig();
    const configKeysList =
      configKeys.data.recordsets[0] && configKeys.data.recordsets[0].length > 0
        ? configKeys.data.recordsets[0].map((y) => {
          const _x = { value: y, displayName: y.NAME };
          return _x;
        })
        : [];
    var deafaulttargetVersion = { displayName: "All", value: { ID: "", IS_CHILD: false, NAME: "All", TYPE: "VERSION" } }
    configKeysList.unshift(deafaulttargetVersion);
    ruleBuilderData.targetVersion = deafaulttargetVersion.value;
    setRulesBasicDetails({
      ...rulesBasicDetailsData,
      configKeys: configKeysList,
      userDetails: userDetails,
      companyList:
        companyList && companyList.data && companyList.data.length > 0
          ? companyList.data.map((y) => {
            const _x = { value: y.id, displayName: y.name };
            return _x;
          })
          : [],
    });

    if (
      editRule &&
      editRuleData &&
      // ruleBuilderData.targetVersion &&
      editRuleData.TYPE_ID &&
      editRuleData.CATEGORY_ID
    ) {
      // const targetVersion = configKeysList.find(
      //   (x) => (x = x.value.ID === ruleBuilderData.targetVersion.ID)
      // );
      const ruleType = configKeysList.find(
        (x) => (x = x.value.ID === editRuleData.TYPE_ID)
      );
      const category = configKeysList.find(
        (x) => (x = x.value.ID === editRuleData.CATEGORY_ID)
      );

      let  componentAtrributeValue = editRuleData.ATTRIBUTE_PROPERTY
      if (editRuleData.ATTRIBUTE_PROPERTY && _.isString(editRuleData.ATTRIBUTE_PROPERTY)) {
        componentAtrributeValue = editRuleData.ATTRIBUTE_PROPERTY.split(",");
      }
      setRuleBuilderData({
        ...ruleBuilderData,
        ruleType: ruleType.value,
        companyId: editRuleData.COMPANY_ID,
        category: category.value,
        ruleCriteriaValue: editRuleData.CRITERIA,
        componentAtrributeValue: componentAtrributeValue
      });
      setEnableNextButton(true);
    } else if (
      ruleBuilderData &&
      ruleBuilderData.targetVersion &&
      ruleBuilderData.ruleType &&
      ruleBuilderData.category
    ) {
      const targetVersion = configKeysList.find(
        (x) => (x = x.value.ID === ruleBuilderData.targetVersion.ID)
      );
      const ruleType = configKeysList.find(
        (x) => (x = x.value.ID === ruleBuilderData.ruleType.ID)
      );
      const category = configKeysList.find(
        (x) => (x = x.value.ID === ruleBuilderData.category.ID)
      );
      setRuleBuilderData({
        ...ruleBuilderData,
        targetVersion: targetVersion.value,
        ruleType: ruleType.value,
        category: category.value
      });
    }
  };

  const handelSelect = async (field, event) => {
    if (field === "ruleType") {
      setRuleBuilderData({
        ...ruleBuilderData,
        [field]: event.target.value,
        ruleComponent: null,
        componentAttribute: null,
        stageData: null,
      });
    } else {
      setRuleBuilderData({
        ...ruleBuilderData,
        [field]: event.target.value,
      });
    }
  };

  const onRemedyChange = (data) => {
    // Convert to raw js object
    const raw = convertToRaw(data.getCurrentContent());
    setRuleBuilderData({
      ...ruleBuilderData,
      remedy: JSON.stringify(raw)
    })

    setHelperErrors({ ...helperErrors, remedy: ""})
  };

  const handleInput = async (field, value) => {
    let newValue = value

    if(field === 'ruleName' || field === 'detail') {
      newValue = newValue.replace(/[^A-Za-z-0-9-_ ]/gi, "");
    }

    if ((field === 'ruleName') && newValue.length > 50) return;
    if (field === 'detail' && newValue.length > 100) return;


    setRuleBuilderData({
      ...ruleBuilderData,
      [field]: newValue,
    });
  };

  const validateForm = () => {
    let enable = false;
    if (
      rulesBasicDetailsData.userDetails &&
      rulesBasicDetailsData.userDetails.role === "super admin"
    ) {
      if (
        ruleBuilderData.companyId &&
        ruleBuilderData.ruleName &&
        ruleBuilderData.ruleType &&
        ruleBuilderData.category
      ) {
        enable = true;
        setEnableNextButton(true);
      } else {
        setEnableNextButton(false);
      }
    } else {
      if (ruleBuilderData.ruleName && ruleBuilderData.ruleType) {
        enable = true;
        setEnableNextButton(true);
      } else {
        enable = false;
        setEnableNextButton(false);
      }
    }
    return enable;
  };

  const onNextButton = () => {
    // setRuleBuilderData(ruleBuilderData);
    const errors = {}
    let isDirty = false
    if(ruleBuilderData && (!ruleBuilderData.remedy  || ruleBuilderData.remedy  === originalRemedy)) {
      errors.remedy = "This Field is required"
      isDirty = true
    }

    if(isDirty) {
      setHelperErrors({ ...helperErrors, ...errors});
    } else {
      props.onhandleNext();
    }
  };

  return (
    <Container fluid={true}>
      <Paper className="rules_stepper_body" elevation={3}>
        <Row className="rules_stepper_body_row">
          <Col xs="6">
            <div className="rules_stepper_title">
              Let's start filling basic details to build a rule{" "}
            </div>
          </Col>
          <Col xs="6">
            <div className="rules_stepper_button">
              <BinarywayButton
                label={"Next"}
                onClick={onNextButton}
                disabled={enableNextButton === false}
              ></BinarywayButton>
            </div>
          </Col>
        </Row>
        {rulesBasicDetailsData.userDetails &&
          rulesBasicDetailsData.userDetails.role === "super admin" && (
            <Row className="rules_stepper_body_row">
              <Col xs="7">
                <BinarywayDropdown
                  placeHolder=""
                  label="Company *"
                  validator={true}
                  errorMessage={"this field is required"}
                  value={ruleBuilderData.companyId || null}
                  options={rulesBasicDetailsData.companyList}
                  onChange={(event) => handelSelect("companyId", event)}
                />
              </Col>
            </Row>
          )}
        <Row className="rules_stepper_body_row">
          <Col xs="7">
            <BinarywayInput
              label={"Name *"}
              type={"text"}
              value={ruleBuilderData.ruleName || null}
              fullWidth={true}
              inputProps={{ maxLength: 50 }}
              validators={["required", "maxLength"]}
              errormessages={["this field is required", "max 50 characters"]}
              onChange={(event) => handleInput("ruleName", event.target.value)}
            ></BinarywayInput>
          </Col>
        </Row>
        <Row className="rules_stepper_body_row">
          <Col xs="7">
            <BinarywayInput
              label={"Description *"}
              type={"text"}
              validators={["required", "maxLength"]}
              errormessages={["this field is required", "max 100 characters"]}
              maxLength={100}
              inputProps={{ maxLength: 100 }}
              value={ruleBuilderData.detail || null}
              fullWidth={true}
              multiline={true}
              onChange={(event) => handleInput("detail", event.target.value)}
            ></BinarywayInput>
          </Col>
        </Row>
        <Row className="rules_stepper_body_row">
          <Col xs="7">
            <span>Remedy * </span>
            <BinarywayRichTextEditor
              onChange={onRemedyChange}
              defaultValue={remedyEditorState}
              inlineToolbar={true}
              error={helperErrors.remedy ?  true :  false}
            />
          </Col>
        </Row>
        <div className="custom-card">
          <div className="custom-card-header">
            <p>
              <span className="float-Left">Select category, version and type</span>
            </p>
          </div>
          <div className="custom-card-body">

            <Row className="rules_stepper_body_row">
              <Col xs="7">
                <BinarywayDropdown
                  placeHolder=""
                  label="Select Blue Prism Version"
                  value={ruleBuilderData.targetVersion || ""}
                  defaultValue={ruleBuilderData.targetVersion || ""}
                  options={rulesBasicDetailsData.configKeys.filter(
                      (obj) => obj.value.TYPE === "VERSION").sort((a,b)=>{a=a.displayName.split(".");b=b.displayName.split(".");
                      return b[0]-a[0] || b[1]-a[1];})
                   }
                  onChange={(event) => handelSelect("targetVersion", event)}
                />
              </Col>
            </Row>

            <Row className="rules_stepper_body_row">
              <Col xs="7">
                <BinarywayDropdown
                  placeHolder=""
                  label="Select Category *"
                  validator={true}
                  errormessages={"Category field is required"}
                  value={ruleBuilderData.category || ""}
                  options={rulesBasicDetailsData.configKeys.filter(
                    (obj) => obj.value.TYPE === "CATEGORY"
                  )}
                  onChange={(event) => handelSelect("category", event)}
                />
              </Col>
            </Row>

            <Row className="rules_stepper_body_row">
              <Col xs="7">
                { (rulesBasicDetailsData.userDetails && rulesBasicDetailsData.userDetails.role === "super admin") ? 
                <BinarywayDropdown
                placeHolder=""
                label="Select Rule Type *"
                validator={true}
                errormessages={"this field is required"}
                value={ruleBuilderData.ruleType || null}
                options={rulesBasicDetailsData.configKeys.filter(
                  (obj) => obj.value.TYPE === "COMPONENT_TYPE" 
                )}
                onChange={(event) => handelSelect("ruleType", event)}
              /> :
              <BinarywayDropdown
                  placeHolder=""
                  label="Select Rule Type *"
                  validator={true}
                  errormessages={"this field is required"}
                  value={ruleBuilderData.ruleType || null}
                  options={rulesBasicDetailsData.configKeys.filter(
                    (obj) => obj.value.TYPE === "COMPONENT_TYPE"  && obj.value.NAME === "Naming Convention"
                  )}
                  onChange={(event) => handelSelect("ruleType", event)}
                />
                  
                }
              </Col>
            </Row>

            {ruleBuilderData.ruleType && (
              <Row className="rules_stepper_body_row">
                <Col xs="7">
                  <Alert severity="info">
                    {getHelperText(ruleBuilderData.ruleType.NAME)}
                  </Alert>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Paper>
    </Container>
  );
};

export default RulesBasicDetails;
