import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import { Typography, Grid} from "@material-ui/core";

import {
  getConfig,
  getRulConfig,
  getChildData,
  getElementData,
  getCompanyListDetails,
  getUserDetailsFromJwtToken,
  getTemplateDetailsByCompanyId,
  getBlock
} from "../../../../../service/service";

import BinarywayButton from "../../../../UI-Components/Button/Button";
import BinarywayDropdown from "../../../../UI-Components/Drop-Down/Drop-Down";
import BinarywayInput from "../../../../UI-Components/Input/Input";
import SuggestionsField from "../../../../UI-Components/SuggestionDropdown/suggestion"
import Checkbox from "@material-ui/core/Checkbox";
import { AppContext } from "../../../../../service/context";
import { RuleBuilderContext } from "../../../../../service/context";
import { ruleCreteriaData } from "../../../../../lib/helperdata";
import RulesPropertiesCss from "./RulesProperties.module.css";
import RulePropertiesPng from "../../../../../assets/BinaryWayLogoBlack.png";
import _, { camelCase, keys } from "lodash";
import { useHistory } from 'react-router-dom';
import { atom, RecoilRoot, useRecoilState, useSetRecoilState } from 'recoil'
const RuleProperties = (props) => {
  const { appData, setAppData } = useContext(AppContext);
  const { ruleBuilderData, setRuleBuilderData } = useContext(
    RuleBuilderContext
  );
  const [rulePropertiesData, setRulePropertiesData] = useState({
    ruleComponent: null,
  });
  const [rulesMasterDetailsData, setRulesMasterDetails] = useState({
    configKeys: [],
    ruleConfigKeys: [],
    companyList: [],
    userDetails: {},
    childConfig: [],
    elementData: null,
    companyBlocks:[]
  });
  const [showPattern, setShowPattern] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showElementPopUp, setShowElementPopUp] = useState(false);
  const [enableNextButton, setEnableNextButton] = useState(false);
  const history = useHistory();
  const [selectAllSpell, setSelectAllSpell] = useState(false)


  useEffect(() => {

    let ruleData = null
    let editRule = false
    if (history && history.location && history.location.state && history.location.state.isEdit) {

      editRule = true;
      ruleData = history.location.state.editData;
    }

    if (!ruleBuilderData.ruleType || !ruleBuilderData.ruleName) {
      window.location = "/Dashboard/Rules";
    }
    onLoadData(editRule, ruleData);
  }, []);


  const setEditRuleData = (configKeys, editRuleData, childConfig) => {
    if (
      editRuleData &&
      editRuleData.COMPONENT_ID
    ) {

      const ruleComponent = configKeys.data.recordsets[0].find(
        (x) => x && x.ID === editRuleData.COMPONENT_ID
      );

      let componentAttribute = null;

      if (editRuleData.PROPERTY_ID) {
        componentAttribute = configKeys.data.recordsets[0].find(
          (x) => x && x.ID === editRuleData.PROPERTY_ID
        );
      }

      let componentAtrributeValue = null;



      if (ruleBuilderData.componentAtrributeValue && _.isObject(ruleBuilderData.componentAtrributeValue) && ruleBuilderData.componentAtrributeValue.ID) {
        componentAtrributeValue = configKeys.data.recordsets[0].find(
          (x) => x && x.ID === ruleBuilderData.componentAtrributeValue.ID
        );
      } else {
        componentAtrributeValue = ruleBuilderData.componentAtrributeValue
      }

      let ruleTypeProperty = null;

      // if(ruleBuilderData.ruleTypeProperty && ruleBuilderData.ruleTypeProperty.ID) {
      //   ruleTypeProperty = configKeys.data.recordsets[0].find(
      //     (x) => x && x.ID === ruleBuilderData.ruleTypeProperty.ID
      //   );
      // }
      ruleBuilderData.stageData = editRuleData.stageData;

      ruleBuilderData.stageData.forEach((x) => {

        if (x.STAGE_ID) {
          x.stage = configKeys.data.recordsets[0].find(
            (obj) => obj.TYPE === "STAGE" && obj.ID === x.STAGE_ID
          );
        }
      });
      let ruleCriteriaValue = ruleBuilderData.ruleCriteriaValue || '';
      const jsonData = editRuleData.JSON_DATA? JSON.parse(editRuleData.JSON_DATA):undefined;
      const camelCaseData = jsonData && jsonData.criteria && jsonData.criteria.length ? jsonData.criteria.find(i=>i.type==1) : undefined;
      const dictionaryData = jsonData && jsonData.criteria && jsonData.criteria.length ? jsonData.criteria.find(i=>i.type==2) : undefined;
      const keyWordData = jsonData && jsonData.criteria && jsonData.criteria.length ? jsonData.criteria.find(i=>i.type==3) : undefined
      const isCamelCase = camelCaseData? camelCaseData.value : 0;
      const isDictionary = dictionaryData? dictionaryData.value : 0;
      const isKeyword = keyWordData? keyWordData.value : 0;
      const spellCheck = jsonData && jsonData.spellCheck && jsonData.spellCheck.length ? jsonData.spellCheck : []

      const selectedData = childConfig.filter(
        (obj) =>
          obj.PARENT_ID === ruleBuilderData.ruleType.ID &&
          obj.SELF_ID === componentAttribute.ID
      );
      let options = [];
      selectedData.forEach((obj) => {
        const key = configKeys.data.recordsets[0].find((item) => item.ID === obj.CHILD_ID);
        options.push({ ...key });
      });
      if (spellCheck.length && options.length === spellCheck.length) {
        setSelectAllSpell(true)
      }
      setRulePropertiesData({
        ...rulePropertiesData,
        ruleComponent: ruleComponent,
        componentAttribute: componentAttribute || null,
        stageData: ruleBuilderData.stageData || null,
        componentAtrributeValue: componentAtrributeValue || null,
        ruleCriteriaValue: ruleCriteriaValue,
        ruleTypeProperty: ruleTypeProperty || null,
        isCamelCase : isCamelCase,
        isDictionary : isDictionary,
        isKeyword : isKeyword,
        spellCheck: spellCheck
      });
    }

  }

  const onLoadData = async (editRule, editRuleData) => {
    const userDetails = getUserDetailsFromJwtToken();
    let companyList = null;
    if (userDetails && userDetails.role === "super admin") {
      companyList = await getCompanyListDetails();
    }
    const configKeys = await getConfig();
    const ruleConfig = await getRulConfig();
    const templatesList = await getTemplateDetailsByCompanyId();
    const companyBlock = await getBlock();
    let childConfig = null;
    if (ruleBuilderData && ruleBuilderData.ruleType) {
      childConfig = await getChildData({
        PARENT_ID: ruleBuilderData.ruleType.ID,
      });
    }
    setRulesMasterDetails({
      ...rulesMasterDetailsData,
      configKeys:
        configKeys.data.recordsets[0] &&
          configKeys.data.recordsets[0].length > 0
          ? configKeys.data.recordsets[0].map((y) => {
            const _x = { value: y, displayName: y.NAME };
            return _x;
          })
          : [],
      ruleConfigKeys:
        ruleConfig.data.recordsets[0] &&
          ruleConfig.data.recordsets[0].length > 0
          ? ruleConfig.data.recordsets[0].map((y) => {
            const _x = { value: y, displayName: y.NAME };
            return _x;
          })
          : [],
      userDetails: userDetails,
      companyList:
        companyList && companyList.data && companyList.data.length > 0
          ? companyList.data.map((y) => {
            const _x = { value: y.id, displayName: y.name };
            return _x;
          })
          : [],
      childConfig: childConfig ? childConfig.data.recordsets[0] : [],
      templatesList: templatesList || [],
      companyBlocks: companyBlock.data.recordsets[0] && companyBlock.data.recordsets[0].length > 0 ? companyBlock.data.recordsets[0].map((y) => {
        const _x = { value: {id:y.ID, name: y.naming_convention, companyId:y.company_id}, displayName: y.naming_convention, id: y.ID};
        return _x;
      })
      : [],
    });

    if (editRule) {
      setEditRuleData(configKeys, editRuleData, childConfig ? childConfig.data.recordsets[0] : []);
    } else if (
      ruleBuilderData &&
      ruleBuilderData.ruleComponent &&
      ruleBuilderData.ruleComponent.ID
    ) {

      const ruleComponent = configKeys.data.recordsets[0].find(
        (x) => x && x.ID === ruleBuilderData.ruleComponent.ID
      );

      let componentAttribute = null;

      if (ruleBuilderData.componentAttribute && ruleBuilderData.componentAttribute.ID) {
        componentAttribute = configKeys.data.recordsets[0].find(
          (x) => x && x.ID === ruleBuilderData.componentAttribute.ID
        );
      }

      let componentAtrributeValue = null;


      if (ruleBuilderData.componentAtrributeValue && _.isObject(ruleBuilderData.componentAtrributeValue) && ruleBuilderData.componentAtrributeValue.ID) {
        componentAtrributeValue = configKeys.data.recordsets[0].find(
          (x) => x && x.ID === ruleBuilderData.componentAtrributeValue.ID
        );


      } else  if (ruleBuilderData.componentAtrributeValue && _.isString(ruleBuilderData.componentAtrributeValue)) {
        componentAtrributeValue = ruleBuilderData.componentAtrributeValue.split(",");
      } else {
        componentAtrributeValue = ruleBuilderData.componentAtrributeValue
      }

      let ruleTypeProperty = null;


      if (ruleBuilderData.ruleTypeProperty && ruleBuilderData.ruleTypeProperty.ID) {
        ruleTypeProperty = configKeys.data.recordsets[0].find(
          (x) => x && x.ID === ruleBuilderData.ruleTypeProperty.ID
        );
      }



      ruleBuilderData.stageData.forEach((x) => {

        if (x.stage && x.stage.ID) {
          x.stage = configKeys.data.recordsets[0].find(
            (obj) => obj.TYPE === "STAGE" && obj.ID === x.stage.ID
          );
        }

        if (x.decisionType && x.decisionType.ID) {
          x.decisionType = configKeys.data.recordsets[0].filter(
            (obj) => obj.TYPE === "DECISION_TYPE" && obj.ID === x.decisionType.ID
          );
        }

      });


      let ruleCriteriaValue = ruleBuilderData.ruleCriteriaValue || '';
      let isCamelCase = ruleBuilderData.isCamelCase || 0;
      let isDictionary =ruleBuilderData.isDictionary || 0;
      let isKeyword = ruleBuilderData.isKeyword || 0;

      setRulePropertiesData({
        ...rulePropertiesData,
        ruleComponent: ruleComponent,
        componentAttribute: componentAttribute || null,
        stageData: ruleBuilderData.stageData || null,
        componentAtrributeValue: componentAtrributeValue || null,
        ruleCriteriaValue: ruleCriteriaValue,
        ruleTypeProperty: ruleTypeProperty || null,
        isCamelCase : isCamelCase,
        isDictionary : isDictionary,
        isKeyword : isKeyword,
      });
    }
    // setRulePropertiesData({
    //   ...ruleBuilderData,
    //   // ruleComponent: ruleBuilderData.ruleComponent,
    //  });
  };

  const onNextButton = () => {
    const _ruleBuilderData = { ...ruleBuilderData, ...rulePropertiesData };
    setRuleBuilderData(_ruleBuilderData);
    props.onhandleNext();
  };

  const handelSelect = async (field, event, value) => {
    if (field === "template") {
      setRulePropertiesData({
        ...rulePropertiesData,
        ["componentAtrributeValue"]: [],
        [field]: event.target.value,
      });
    }
    else if (field === "componentAttribute") {
      if (
        ruleBuilderData.ruleType.NAME === "Naming Convention" &&
        event.target.value &&
        event.target.value.NAME === "Application Modeller"
      ) {
        setEnableNextButton(true);
      }

      setRulePropertiesData({
        ...rulePropertiesData,
        ["componentAtrributeValue"]: [],
        [field]: event.target.value,
      });

      if (
        event.target.value &&
        event.target.value.NAME === "Application Modeller"
      ) {
        const elementData = await getElementData();
        setRulesMasterDetails({
          ...rulesMasterDetailsData,
          elementData: elementData.data,
        });
      }
    } 
    else if (field === "spellCheck") {
      setEnableNextButton(true);
      let selectedStages = rulePropertiesData.spellCheck && rulePropertiesData.spellCheck.length ? rulePropertiesData.spellCheck : [];
      const findIdx = selectedStages.map(i=>i.id).indexOf(value.ID);
      if (findIdx > -1) {
        selectedStages.splice(findIdx, 1);
      } else {
        selectedStages.push({id:value.ID,name:value.NAME});
      }
      setRulePropertiesData({
        ...rulePropertiesData,
        ["spellCheck"]: selectedStages
      });
    } else if (field === "spellCheckAll") {
      setEnableNextButton(true);
      setSelectAllSpell(event.target.checked)
      if (event.target.checked) {
        const _spellCheckData = value.map(u => ({ id: u.value.ID, name: u.value.NAME }));
        setRulePropertiesData({
          ...rulePropertiesData,
          ["spellCheck"]: _spellCheckData && _spellCheckData.length ? _spellCheckData : []
        });
      } else {
        setRulePropertiesData({
          ...rulePropertiesData,
          ["spellCheck"]: []
        });
      }
    }
    else if (field === "blocks") {
      setRulePropertiesData({
        ...rulePropertiesData,
        ["BlockNames"]: event.target.value
      });
    }
    else if (field === "ruleComponent") {
      setRulePropertiesData({
        ...rulePropertiesData,
        ["componentAttribute"]: null,
        ["stageData"]: [{}],
        [field]: event.target.value,
      });
    } else if (field === "stage") {
      setRulePropertiesData({
        ...rulePropertiesData,
        ["bussinesValue"]: null,
        [field]: event.target.value,
      });
    } else if (field === "stagePairing") {
      setRulePropertiesData({
        ...rulePropertiesData,
        ["stagebussinesValue"]: null,
        [field]: event.target.value,
      });
    } else if (field === "bussinesValue") {
      setRulePropertiesData({
        ...rulePropertiesData,
        ["business Action"]: null,
        [field]: event.target.value,
      });
    } else if (field === "stagebussinesValue") {
      setRulePropertiesData({
        ...rulePropertiesData,
        ["stagebusiness Action"]: null,
        [field]: event.target.value,
      });
    } else if (field === "ruleCriteriaValue") {
      setRulePropertiesData({
        ...rulePropertiesData,
        [field]: event.target.value,
      });
      if(event.target.value)
        setEnableNextButton(true);
      else
        setEnableNextButton(false);
    } else if (field === "isCamelCase") {
      if(event.target.checked) {
        setRulePropertiesData({
          ...rulePropertiesData,
          [field]: 1,
        })
      } else {
        setRulePropertiesData({
          ...rulePropertiesData,
          [field]: 0,
        })
      }
    } else if (field === "isDictionary") {
      if(event.target.checked) {
        setRulePropertiesData({
          ...rulePropertiesData,
          [field]: 1,
        })
      } else {
        setRulePropertiesData({
          ...rulePropertiesData,
          [field]: 0,
        })
      }
    }
    else if (field === "isKeyword") {
      if(event.target.checked) {
        setRulePropertiesData({
          ...rulePropertiesData,
          [field]: 1,
        })
      } else {
        setRulePropertiesData({
          ...rulePropertiesData,
          [field]: 0,
        })
      }
    } else {
      setRulePropertiesData({
        ...rulePropertiesData,
        [field]: event.target.value,
      });
    }
  };

  const getComponentOptions = (typeId) => {
    const { configKeys, ruleConfigKeys } = rulesMasterDetailsData;
    let options = [];
    ruleConfigKeys
      .filter((rule) => rule.value.RULE_TYPE_ID === typeId)
      .map((obj) => {
        const selectedOption = configKeys.filter(
          (config) => config.value.ID === obj.value.COMPONENT_ID
        );
        options.push(selectedOption[0]);
      });
    return options;
  };

  const getTemplateOption = (rule) => {
    let { templatesList } = rulesMasterDetailsData;
    const userDetails = getUserDetailsFromJwtToken();


    templatesList = templatesList && templatesList.data && templatesList.data.length > 0
      ? templatesList.data : []

    if (userDetails && userDetails.role === "super admin")  {
      templatesList = templatesList && templatesList.length > 0 ? templatesList.filter((tem) => tem.company_id === rule.companyId)
      .map((y) => {
        const _x = { value: y.id, displayName: y.name };
        return _x;
      }) : []
    } else {
      templatesList = templatesList && templatesList.length > 0 ? templatesList.map((y) => {
        const _x = { value: y.id, displayName: y.name };
        return _x;
      }) : []
    } 

    return templatesList;
  }

  const isNodeHaveChild = (parentId, selfId) => {
    const { childConfig } = rulesMasterDetailsData;
    const selectedData = childConfig.filter(
      (obj) => obj.PARENT_ID === parentId && obj.SELF_ID === selfId
    );

    if (selectedData.length) {
      return true;
    }
    return false;
  };

  const getNodeChild = (parentId, selfId) => {
    const { childConfig, configKeys } = rulesMasterDetailsData;
    const selectedData = childConfig.filter(
      (obj) => obj.PARENT_ID === parentId && obj.SELF_ID === selfId
    );
    let result = [];
    selectedData.forEach((obj) => {
      const key = configKeys.filter((item) => item.value.ID === obj.CHILD_ID);
      result.push(...key);
    });
    return result;
  };

  const addStageBlock = () => {
    rulePropertiesData.stageData.push({});
    setRulePropertiesData({
      ...rulePropertiesData,
      stageData: rulePropertiesData.stageData,
    });
  };

  const renderChild = (parentId, parentName) => {
    const { configKeys, childConfig } = rulesMasterDetailsData;
    if (parentName === "Run Mode") {
      let options = configKeys.filter(
        (obj) =>
          obj.value.TYPE === "COMPONENT_PROPERTY_VALUE" &&
          obj.value.PRE_ID === parentId
      );
      return options.map((option, index) => (
        <div>
          {index == 0 ? (
            <label style={{ display: "block" }}>
              Component Property Value:
            </label>
          ) : null}
          <input
            type="checkbox"
            value={option.value.NAME}
            id={option.value.NAME}
            checked={rulePropertiesData.componentAtrributeValue && _.isArray(rulePropertiesData.componentAtrributeValue) && rulePropertiesData.componentAtrributeValue.filter((item) => item === option.value.NAME).length}
            onChange={(e) => {
              let componentAtrributeValue =
                rulePropertiesData.componentAtrributeValue;
              const checked = rulePropertiesData.componentAtrributeValue && rulePropertiesData.componentAtrributeValue.filter((item) => item === option.value.NAME).length
              if (!checked) {
                componentAtrributeValue.push(option.value.NAME);
              } else {
                componentAtrributeValue = componentAtrributeValue.filter(
                  (item) => item !== option.value.NAME
                );
              }
              rulePropertiesData.componentAtrributeValue = componentAtrributeValue;
              setRulePropertiesData({ ...rulePropertiesData });
            }}
          />
          <span>{option.value.NAME}</span>
        </div>
      ));
    } else {
      const selectedData = childConfig.filter(
        (obj) =>
          obj.PARENT_ID === ruleBuilderData.ruleType.ID &&
          obj.SELF_ID === parentId
      );
      let options = [];
      selectedData.forEach((obj) => {
        const key = configKeys.filter((item) => item.value.ID === obj.CHILD_ID);
        options.push(...key);
      });

      if (!options.length) return;

      return (
        <div>
          <BinarywayDropdown
            placeHolder=""
            label="Component Property Value"
            value={rulePropertiesData.componentAtrributeValue || null}
            options={options}
            onChange={(value) => handelSelect("componentAtrributeValue", value)}
          />
          {rulePropertiesData.componentAtrributeValue &&
            rulePropertiesData.componentAtrributeValue.NAME === "Elements" ? (
              <span className="link" onClick={() => setShowElementPopUp(true)}>
                Pre-defined naming for elements
              </span>
            ) : null}
        </div>
      );
    }
  };

  const childCheckbox=(parentId, parentName)=>{
    const { configKeys, childConfig } = rulesMasterDetailsData;
    const selectedData = childConfig.filter(
      (obj) =>
        obj.PARENT_ID === ruleBuilderData.ruleType.ID &&
        obj.SELF_ID === parentId
    );
    let options = [];
    selectedData.forEach((obj) => {
      const key = configKeys.find((item) => item.value.ID === obj.CHILD_ID);
      options.push({...key});
    });

    if (!options.length) return;
    return (
      <div>
        <Grid container>
          <Grid item xs={1}>
            <Checkbox
              style={{ borderRadius: "100%", paddingRight: "2px" }}
              onChange={(e) => handelSelect("spellCheckAll", e, options)}
              checked={selectAllSpell}
            />
          </Grid>
          <Grid item xs={10}>
            <Typography style={{ paddingTop: "9px" }}>All</Typography>
          </Grid>
        </Grid>
        {options.map((i) => (
          <>
            <Grid container>
              <Grid item xs={1}>
                <Checkbox
                  style={{ borderRadius: "100%", paddingRight: "2px" }}
                  onChange={(e) => handelSelect("spellCheck", e, i.value)}
                  checked={rulePropertiesData.spellCheck && rulePropertiesData.spellCheck.length &&
                    rulePropertiesData.spellCheck.find(sp => sp.id === i.value.ID)?true:false}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography style={{ paddingTop: "9px" }}>
                  {i.value.NAME}
                </Typography>
              </Grid>
            </Grid>
          </>
        ))}
      </div>
    );

  }

  const renderStageDetailBlock = () => {
    const { configKeys } = rulesMasterDetailsData;
    return (
      rulePropertiesData.stageData &&
      rulePropertiesData.stageData.map((data, id) => {
        return (
          <>
            <Row className="rules_stepper_body_row">
              <Col xs="4">
                <div style={{ display: "flex" }}>
                  <BinarywayDropdown
                    placeHolder=""
                    label="Stage Property"
                    value={data.stage || null}
                    options={configKeys.filter(
                      (obj) => obj.value.TYPE === "STAGE"
                    )}
                    onChange={(value) => handleStageSelect("stage", value, id)}
                  />
                  {data && id !== 0 && (
                    <span
                      onClick={() => onDeleteStage(id)}
                      className={RulesPropertiesCss.rule_properties_deleteicon}
                    >
                      <i
                        className="fa fa-trash-o deleteicon"
                        aria-hidden="true"
                      ></i>
                    </span>
                  )}
                </div>
              </Col>
              {data.stage &&
                ruleBuilderData.ruleType.ID === 5 &&
                data.stage.NAME === "Decision" && (
                  <>
                    <Col xs="4">
                      <BinarywayDropdown
                        placeHolder=""
                        label="Input Type"
                        value={data.decisionType || null}
                        options={configKeys.filter(
                          (obj) => obj.value.TYPE === "DECISION_TYPE"
                        )}
                        onChange={(value) =>
                          handleStageSelect("decisionType", value, id)
                        }
                      />
                    </Col>
                    <Col xs="4">
                      <BinarywayInput
                        fullWidth={true}
                        label={"Expresssion"}
                        type={"text"}
                        onChange={(e) =>
                          handleStageSelect(
                            "businessValue",
                            {
                              NAME: e.target.value,
                            },
                            id
                          )
                        }
                      ></BinarywayInput>
                    </Col>
                  </>
                )}

              {data.stage && renderStageChild(data.stage.ID, data, id)}
              {data.businessValue &&
                renderStageChild2(data.businessValue.ID, data, id)}
            </Row>
          </>
        );
      })
    );
  };

  const renderBlockDetails = (companyId) => {
    const { companyBlocks } = rulesMasterDetailsData;
    return (
      <>
        <Row className="rules_stepper_body_row">
          <Col xs="4">
            <div style={{ display: "flex" }}>
              <BinarywayDropdown
                placeHolder=""
                label="Block"
                value={rulePropertiesData.BlockNames || null}
                options={companyBlocks.filter(
                  (obj) => obj.value.companyId === companyId
                )}
                onChange={(value) => handelSelect("blocks", value)}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const handleStageSelect = (field, event, index) => {
    if (field === "stage") {
      rulePropertiesData.stageData[index] = {};
    }
    if (event && !event.target && (field === 'businessValue' || field === 'businessAction')) {
      rulePropertiesData.stageData[index][field] = event;
    }
    else {
      rulePropertiesData.stageData[index][field] = event.target.value;
    }
    setRulePropertiesData({
      ...rulePropertiesData,
      stageData: rulePropertiesData.stageData,
    });
  };

  const onDeleteStage = (id) => {
    rulePropertiesData.stageData.splice(id, 1);
    setRulePropertiesData({
      ...rulePropertiesData,
      stageData: rulePropertiesData.stageData,
    });
  };

  const renderStageChild = (stageID, data, id) => {
    const { configKeys, childConfig } = rulesMasterDetailsData;

    if (
      ruleBuilderData.ruleType.NAME === "Process Flow" &&
      data.stage.NAME === "Recover"
    ) {
      return (
        <Col xs="4">
          <BinarywayInput
            fullWidth={true}
            label={"Max Attempts"}
            type={"text"}
            onChange={(e) =>
              handleStageSelect(
                "businessValue",
                {
                  NAME: e.target.value,
                },
                id
              )
            }
          ></BinarywayInput>
        </Col>
      );
    }
    const selectedData = childConfig.filter(
      (obj) =>
        obj.PARENT_ID === ruleBuilderData.ruleType.ID && obj.SELF_ID === stageID
    );

    let result = [];

    selectedData.forEach((obj) => {
      const key = configKeys.filter((item) => item.value.ID === obj.CHILD_ID);
      result.push(...key);
    });

    if (!result.length) return;

    return (
      <Col xs="4">
        <BinarywayDropdown
          placeHolder=""
          label="Business Object"
          value={data.businessValue || null}
          options={result}
          onChange={(value) => handleStageSelect("businessValue", value, id)}
        />
      </Col>
    );
  };

  const renderStageChild2 = (stageID, data, id) => {
    const { configKeys, childConfig } = rulesMasterDetailsData;

    if (
      ruleBuilderData.ruleType.NAME === "Structure" &&
      (data.businessValue.NAME === "Input" ||
        data.businessValue.NAME === "Output")
    ) {
      return (
        <Col xs="4">
          <BinarywayInput
            fullWidth={true}
            label={"Block Text"}
            type={"text"}
            onChange={(e) =>
              handleStageSelect(
                "businessAction",
                {
                  NAME: e.target.value,
                },
                id
              )
            }
          ></BinarywayInput>
        </Col>
      );
    }

    const selectedData = childConfig.filter(
      (obj) =>
        obj.PARENT_ID === ruleBuilderData.ruleType.ID && obj.SELF_ID === stageID
    );
    let result = [];
    selectedData.forEach((obj) => {
      const key = configKeys.filter((item) => item.value.ID === obj.CHILD_ID);
      result.push(...key);
    });

    if (!result.length) return;

    return (
      <Col xs="4">
        <BinarywayDropdown
          placeHolder=""
          label="Action"
          value={data.businessAction || null}
          options={result}
          onChange={(value) => handleStageSelect("businessAction", value, id)}
        />
      </Col>
    );
  };

  return (
    <>
      <Container fluid={true}>
        <Paper className="rules_stepper_body" elevation={3}>
          <Row>
            <Col xs="6">
              <div className="rules_stepper_title">
                Great, you've selected{" "}
                {ruleBuilderData &&
                  ruleBuilderData.ruleType &&
                  ruleBuilderData.ruleType.NAME}
              </div>
            </Col>
            <Col xs="6">
              <div className="rules_stepper_button">
                <BinarywayButton
                  label={"Back"}
                  variant={"outlined"}
                  onClick={() => {
                    const _ruleBuilderData = {
                      ...ruleBuilderData,
                      ...rulePropertiesData,
                    };
                    setRuleBuilderData(_ruleBuilderData);
                    props.onhandleBack();
                  }}
                  className="rules_stepper_button"
                ></BinarywayButton>
                <div style={{ marginLeft: '10px', display: 'inline-block', float: 'right' }}>
                  <BinarywayButton
                    label={"Next"}
                    onClick={onNextButton}
                    className="rules_stepper_button"
                    disabled={ruleBuilderData.ruleType && ruleBuilderData.ruleType.NAME ==="Naming Convention" ? enableNextButton === false : ""}
                  ></BinarywayButton> 
                </div>
              </div>
            </Col>
          </Row>
          <div className={RulesPropertiesCss.rule_properties_customcard}>
            <div className="custom-card-header">
              <p>
                <span className="float-Left">
                  Let's start filling properties for rule
                </span>
                {/* <span id="tooltipHeader">?</span> */}
              </p>
              {/* <Tooltip
                placement="right"
                isOpen={showTooltip}
                target="tooltipHeader"
                toggle={() => setShowTooltip(!showTooltip)}
              >
                tool tip
              </Tooltip> */}
            </div>
            <div className="custom-card-body">
              <Row>
                <Col xs="7">
                  {rulesMasterDetailsData.configKeys &&
                    rulesMasterDetailsData.configKeys.length > 0 &&
                    ruleBuilderData.ruleType && (
                      <>
                        <Row className="rules_stepper_body_row">
                          <Col xs="12">
                            {
                              ruleBuilderData.ruleType && (
                                ruleBuilderData.ruleType.NAME === "Compare Template" ?
                                  <BinarywayDropdown
                                    placeHolder=""
                                    label="Select Template"
                                    // id={"Component-DropDown"}
                                    value={
                                      rulePropertiesData.template ||
                                      ruleBuilderData.template ||
                                      null
                                    }
                                    options={getTemplateOption(
                                      ruleBuilderData
                                    )}
                                    onChange={(value) =>
                                      handelSelect("template", value)
                                    }
                                  /> :
                                  <BinarywayDropdown
                                    placeHolder=""
                                    label="Component"
                                    // id={"Component-DropDown"}
                                    value={
                                      rulePropertiesData.ruleComponent ||
                                      ruleBuilderData.ruleComponent ||
                                      null
                                    }
                                    options={getComponentOptions(
                                      ruleBuilderData.ruleType.ID
                                    )}
                                    onChange={(value) =>
                                      handelSelect("ruleComponent", value)
                                    }
                                  />
                              )}
                          </Col>
                        </Row>
                        {ruleBuilderData.ruleType ? (
                          <>
                            
                            {ruleBuilderData.ruleType.NAME ===
                              "Value Check" && (
                                <>
                                  <Row className="rules_stepper_body_row">
                                    <Col xs="12">
                                      <BinarywayDropdown
                                        placeHolder=""
                                        label="Operator"
                                        value={
                                          rulePropertiesData.ruleCriteria || null
                                        }
                                        options={rulesMasterDetailsData.configKeys.filter(
                                          (obj) => obj.value.TYPE === "OPERATOR"
                                        )}
                                        onChange={(value) =>
                                          handelSelect("ruleCriteria", value)
                                        }
                                      />
                                    </Col>
                                  </Row>{" "}
                                  <Row className="rules_stepper_body_row">
                                    <Col xs="12">
                                      {/* <br /> */}
                                      <BinarywayInput
                                        fullWidth={true}
                                        label={"Operand"}
                                        type={"text"}
                                        onChange={(event) =>
                                          handelSelect("ruleCriteriaValue", event)
                                        }
                                      ></BinarywayInput>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            {ruleBuilderData.ruleType.id === "3" && (
                              <>
                                <Row className="rules_stepper_body_row">
                                  <Col xs="12">
                                    <BinarywayDropdown
                                      placeHolder=""
                                      label="Criteria"
                                      value={
                                        rulePropertiesData.ruleCriteria || null
                                      }
                                      options={ruleCreteriaData}
                                      onChange={(value) =>
                                        handelSelect("ruleCriteria", value)
                                      }
                                    />
                                  </Col>
                                </Row>{" "}
                                <Row className="rules_stepper_body_row">
                                  <Col xs="12">
                                    <BinarywayInput
                                      fullWidth={true}
                                      label={"Value"}
                                      type={"number"}
                                      onChange={(e) =>
                                        handelSelect(
                                          "ruleCriteriaValue",
                                          e.target.value
                                        )
                                      }
                                    ></BinarywayInput>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </>
                        ) : null}
                        {ruleBuilderData.ruleType &&
                          ruleBuilderData.ruleType.RULE_PROPERTY && (
                            <Row className="rules_stepper_body_row">
                              <Col xs="12">
                                {
                                  <span>
                                    <BinarywayDropdown
                                      placeHolder=""
                                      label="Rule Property"
                                      value={
                                        rulePropertiesData.ruleTypeProperty ||
                                        null
                                      }
                                      options={rulesMasterDetailsData.configKeys.filter(
                                        (obj) =>
                                          obj.value.TYPE ===
                                          "RULE_TYPE_PROPERTY" &&
                                          obj.value.PRE_ID ===
                                          ruleBuilderData.ruleType.ID
                                      )}
                                      onChange={(value) =>
                                        handelSelect("ruleTypeProperty", value)
                                      }
                                    />
                                  </span>
                                }
                              </Col>
                            </Row>
                          )}
                        <Row className="rules_stepper_body_row">
                          <Col xs="12">
                            {rulePropertiesData.ruleComponent &&
                              ruleBuilderData.ruleType &&
                              rulePropertiesData.ruleComponent.IS_CHILD &&
                              rulePropertiesData.ruleComponent.NAME!=="Spell Check"&&
                              isNodeHaveChild(
                                ruleBuilderData.ruleType.ID,
                                rulePropertiesData.ruleComponent.ID
                              ) ? (
                                <span>
                                  <BinarywayDropdown
                                    placeHolder=""
                                    label="Component Property"
                                    value={
                                      rulePropertiesData.componentAttribute ||
                                      null
                                    }
                                    options={getNodeChild(
                                      ruleBuilderData.ruleType.ID,
                                      rulePropertiesData.ruleComponent.ID
                                    )}
                                    onChange={(value) =>
                                      handelSelect("componentAttribute", value)
                                    }
                                  />
                                </span>
                              ) : null}
                          </Col>
                        </Row>
                        <Row className="rules_stepper_body_row">
                          <Col xs="12">
                            {rulePropertiesData.componentAttribute &&
                              ruleBuilderData.ruleType &&
                              rulePropertiesData.componentAttribute.IS_CHILD ? (
                                <span>
                                  {renderChild(
                                    rulePropertiesData.componentAttribute.ID,
                                    rulePropertiesData.componentAttribute.NAME
                                  )}
                                </span>
                              ) : null}
                          </Col>
                        </Row>
                        <Row className="rules_stepper_body_row">
                          <Col xs="12">
                            {rulePropertiesData.ruleComponent &&
                              ruleBuilderData.ruleType &&
                              rulePropertiesData.ruleComponent.IS_CHILD && rulePropertiesData.ruleComponent.NAME==='Spell Check' ? (
                                <span>
                                  {childCheckbox(
                                    rulePropertiesData.ruleComponent.ID,
                                    rulePropertiesData.ruleComponent.NAME
                                  )}
                                </span>
                              ) : null}
                          </Col>
                        </Row>

                        {((rulePropertiesData.ruleComponent &&
                          rulePropertiesData.ruleComponent.NAME === "Stage") ||
                          (rulePropertiesData.componentAttribute &&
                            rulePropertiesData.componentAttribute.NAME ===
                            "Stage")) &&
                          renderStageDetailBlock()}
                        {((rulePropertiesData.ruleComponent &&
                             ruleBuilderData.ruleType
                               && rulePropertiesData.ruleComponent.NAME==='Variables in Custom Block')) &&
                          renderBlockDetails(ruleBuilderData.companyId)}
                        {((rulePropertiesData.ruleComponent &&
                          rulePropertiesData.ruleComponent.NAME === "Stage") ||
                          (rulePropertiesData.componentAttribute &&
                            rulePropertiesData.componentAttribute.NAME ===
                            "Stage")) &&
                          (ruleBuilderData.ruleType.NAME === "Process Flow" ||
                            ruleBuilderData.ruleType.NAME === "Exists") && (
                            <Row className="rules_stepper_body_row">
                              <Col xs="12">
                                <BinarywayButton
                                  label={"Add New Stage"}
                                  onClick={addStageBlock}
                                ></BinarywayButton>
                              </Col>
                            </Row>
                          )}

                        {ruleBuilderData.ruleType && ruleBuilderData.ruleType.NAME ===
                              "Naming Convention" &&
                              rulePropertiesData.ruleComponent && (rulePropertiesData.componentAttribute && rulePropertiesData.componentAttribute.NAME === 'Application Modeller'? false : rulePropertiesData.ruleComponent.NAME==='Spell Check' ? false : true )  && 
                              (
                                <Row className="rules_stepper_body_row">
                                  <Col xs="12">
                                    {/* <Tooltip
                                      placement="right"
                                      isOpen={show3.3.rn}
                                      target="tooltipPattern"
                                      toggle={() =>
                                        setShowPattern(!showPattern)
                                      }
                                    >
                                      regex101.com
                                    </Tooltip>

                                    <label id="tooltipPattern">?</label> */}
                                  <RecoilRoot>
                                   <SuggestionsField
                                        fullWidth={true}
                                        label={"Pattern(Regex) *"}
                                        validators={["maxLength"]}
                                        errormessages={["max 50 characters"]}
                                        type={"text"}
                                        onChange={(e) =>
                                          handelSelect("ruleCriteriaValue", e)
                                        }
                                        value={
                                          rulePropertiesData.ruleCriteriaValue ||
                                          null
                                        }
                                    ></SuggestionsField>
                                  </RecoilRoot>
                                 </Col>
                                </Row>
                              )
                          }
                          {ruleBuilderData.ruleType && ruleBuilderData.ruleType.NAME ===
                              "Naming Convention2" &&
                              rulePropertiesData.ruleComponent && (rulePropertiesData.componentAttribute && rulePropertiesData.componentAttribute.NAME === 'Application Modeller' ? false : true ) && 
                              (
                                <Row className="rules_stepper_body_row">
                                  <Col xs="12">
                                    <BinarywayInput
                                      fullWidth={true}
                                      label={"Pattern(Regex) *"}
                                      validators={["maxLength"]}
                                      errormessages={["max 50 characters"]}
                                      type={"text"}
                                      onChange={(e) =>
                                        handelSelect("ruleCriteriaValue", e)
                                      }
                                      value={rulePropertiesData.ruleCriteriaValue || null}
                                    ></BinarywayInput>
                                    
                                    <div style={{display:"flex", alignItems:"center", marginRight:'5px'}}>
                                      <Checkbox
                                        checked={rulePropertiesData.isCamelCase? true : false}
                                        color="primary"
                                        onChange={(e) =>{
                                          handelSelect("isCamelCase", e)
                                        }}
                                    
                                        style={{borderRadius:'100%', paddingRight:'2px'}}
                                      />{" "}
                                      <Typography style={{ marginBottom: "2px" }}>
                                       Camel Case
                                      </Typography>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center", marginRight:'5px'}}>
                                      <Checkbox
                                        checked={rulePropertiesData.isDictionary? true : false}
                                        color="primary"
                                        onChange={(e) =>{
                                          handelSelect("isDictionary", e)
                                        }}
                                    
                                        style={{borderRadius:'100%', paddingRight:'2px'}}
                                      />{" "}
                                      <Typography style={{ marginBottom: "2px" }}>
                                       Inclusion Dictionary
                                      </Typography>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center", marginRight:'5px'}}>
                                      <Checkbox
                                        checked={rulePropertiesData.isKeyword? true : false}
                                        color="primary"
                                        onChange={(e) =>{
                                          handelSelect("isKeyword", e)
                                        }}
                                    
                                        style={{borderRadius:'100%', paddingRight:'2px'}}
                                      />{" "}
                                      <Typography style={{ marginBottom: "2px" }}>
                                       Exclusion Keywords
                                      </Typography>
                                    </div>
                                  </Col>
                                </Row>
                              )
                          }         
                        {/* <Row className="rules_stepper_body_row">
                          <Col xs="12">
                            <Alert severity="info">
                              A component content management system is a content
                              management system that manages content at a
                              granular level rather than at the document level.
                              Each component represents a single topic, concept
                              or asset
                            </Alert>
                          </Col>
                        </Row> */}
                      </>
                    )}
                </Col>
                <Col xs="5">
                  <Paper elevation={3}>
                    <div
                      className={
                        RulesPropertiesCss.rule_properties_right_imagediv
                      }
                    >
                      <img
                        className={
                          RulesPropertiesCss.rule_properties_right_image
                        }
                        src={RulePropertiesPng}
                        alt="BinaryWayLogo"
                      />
                    </div>
                    <div
                      className={
                        RulesPropertiesCss.rule_properties_right_header
                      }
                    >
                      BY B1NARYWAY
                    </div>
                    <div
                      className={
                        RulesPropertiesCss.rule_properties_right_subheader
                      }
                    >
                      Your Digital Transformation Partners
                    </div>
                  </Paper>
                </Col>
              </Row>
            </div>
          </div>
        </Paper>
      </Container>
      {showElementPopUp && (
        <Modal
          isOpen={showElementPopUp}
          toggle={() => setShowElementPopUp(false)}
        >
          <ModalHeader toggle={() => setShowElementPopUp(false)}>
            Element Example
          </ModalHeader>
          <ModalBody>
            {rulesMasterDetailsData.elementData.recordset.length ? (
              <table id="rules">
                <tbody>
                  <tr>
                    <th>Type</th>
                    <th>Regex</th>
                    <th>Example</th>
                  </tr>
                  {rulesMasterDetailsData.elementData.recordset.map(
                    (obj, id) => (
                      <tr>
                        <td>{obj.TYPE}</td>
                        <td>{obj.REGEX}</td>
                        <td>{obj.EXAMPLE}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            ) : null}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
export default RuleProperties;
